

.footer_Component {

    height: 20rem;
    height: max(15vh, 20rem);
    overflow: hidden;    /* Prevent margins from collasping */
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


}



