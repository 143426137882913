/*


    Global styles applicable to every pages
    V.P.R - 7/02/2022


*/


/*
  Global variables of the platform
*
*/


:root {
  /* Maximum width the content of the page can take */
  --max-width-page-content: 90rem;
  --max-width-page-content: min(95%, 90rem);

  /* Header's height.  */
  --headersHeight : 5rem;

  /* Main colors */
  --red: 194, 117, 117;
  --pink: 243, 154, 169; /* New */
  --blue: 199, 239,251;  /* New */
  --beige: 255, 254, 246;
  --yellow: 241, 255, 177;
  --green: 137, 175, 133;
  --grey: 100, 100, 100;
  

  /* Dark color for the text and background-colors */
  --dark: 33, 33, 33;

  /* Main font */
  --generalFont: 'Wigrum', Helvetica, sans-serif;
  --titleFont: 'Wigrum', Helvetica, sans-serif;

  /* Common border radius through the website */
  --commonRadius: 1.5em;


}

html{
  scroll-behavior:smooth;
}

body, html {
  margin: 0;
  /*font: 400 15px/1.8 "Lato", sans-serif;*/
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  
  /* Default text size */
  line-height: 1.4;

  /* Default color */
  color: rgb(var(--dark));

}

main {
  min-height: 90vh;

}

* {
  box-sizing: border-box;
}


/*
*
*   Font settings
*
*/

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

h1, h2, h3 {
  line-height: 1.1;
  font-weight: normal;
}

h4, h5, h6 {
  font-weight: 700;
}


h1 { 
  font-size: 3rem; /* For older browsers */
  font-size: clamp(2.5rem, 3.5rem, 5vw);
  font-weight: bold; 
}
h2 { 
  font-size: 2.5rem;  /* For older browsers */
  font-size: clamp(2rem, 3rem, 4vw);
}
h3 { 
  font-size: 1.75rem; 
  font-size: clamp(1.25rem, 1.75rem, 3vw);
}
h4 { font-size: 1.1rem; }
h5 { font-size: 1rem; }
h6 { font-size: 0.8rem; }

.strong { font-weight: bold; }

/*
    Functionnalities
*/
.d-flex { display: flex; }
.flex-wrap { flex-wrap: wrap;}
.flex-direction-column { flex-direction: column; }
.flex-align-center { align-items: center; }
.flex-align-end { align-items: end;}
.flex-align-start { align-items: flex-start; }
.flex-justify-center { justify-content: center; }
.flex-justify-start { justify-content: flex-start; }
.flex-justify-between { justify-content: space-between; }
.flex-justify-end { justify-content: flex-end;}

/*
  General classes
*/
.h-100 { height: 100%; }
.w-100 { width: 100%; }

/* Margin */
.m-0 { margin: 0;}
.m-1 { margin: 0.5rem; }
.m-2 { margin: 1rem; }
.m-3 { margin: 1.5rem; }

.ml-0 { margin-left: 0; }
.ml-1 { margin-left: 0.5rem; }
.ml-2 { margin-left: 1rem; }
.ml-3 { margin-left: 1.5rem; }

.mt-0 { margin-top: 0; }
.mt-1 { margin-top: 0.5rem; }
.mt-2 { margin-top: 1rem; }
.mt-3 { margin-top: 1.5rem; }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: 0.5rem; }
.mb-2 { margin-bottom: 1rem; }
.mb-3 { margin-bottom: 1.5rem; }

/* Padding */
.pl-0 { padding-left: 0; }
.pl-1 { padding-left: 0.5rem; }
.pl-2 { padding-left: 1rem; }
.pl-3 { padding-left: 1.5rem; }

.list-style-disc { list-style-type: disc; } 
.list-style-number { list-style-type: decimal;}

.fs08 { font-size: 0.8rem; }
.fs09 { font-size: 0.9rem; }
.fs1 { font-size: 1rem; }
.fs11 { font-size: 1.1rem; }
.fs12 { font-size: 1.2rem; }
.fs13 { font-size: 1.3rem; }
.fs2 { font-size: 2rem; }

/*
    Recurent Text modifications
*/
.text-align-center { text-align: center; }

/* Section presentation */
.section-presentation {
    font-weight: lighter;
    font-size: 1.6rem;
    border-bottom: 0.1rem solid rgb(194, 194, 194);
    margin: 0;
    padding: 0.5rem 0;
    color: grey;
    text-transform: uppercase;
}

/*
*
*    Colors settings
*
*/

/* blue color */
.blue { color: rgb(var(--blue)); }
.blue_BG { background-color: rgb(var(--blue)); }

/* Pink color */
.pink { color: rgb(var(--pink));}
.pink_BG { background-color: rgb(var(--pink)); }

/* red */
.red { color: rgb(var(--red));}
.red_BG { background-color: rgb(var(--red)); }

/* Beige */
.beige { color: rgb(var(--beige)); }
.beige_BG { background-color: rgb(var(--beige)); }

/* Yellow */
.yellow { color: rgb(var(--yellow)); }
.yellow_BG { background-color: rgb(var(--yellow)); }

/* Green */
.green { color: rgb(var(--green)); }
.green_BG { background-color: rgb(var(--green)); }

/* White color */
.white { color: white; }

/* Dark color */
.dark { color: rgb(var(--dark)); }
.dark_BG { background-color: rgb(var(--dark)); }

.cursorPointer { cursor: pointer; }
.commonRadius { border-radius: var(--commonRadius); }

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  float:left;
}

.strong { font-weight: bold; }

.absolute-covering{
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.section-title {
  min-height: 15rem;
  min-height: max(25vh, 15rem);
}

.bg-img {
  display: flex;
  min-height: 35rem;  
  min-height: min(35rem, 60vh);      
}

.dark-bg { background-color: rgba(25, 25, 25, 0.3);}

/**
*   Remove de the bullet
*/
li { list-style-type: none;}

/*
  Remove automatic padding
*/
ul {padding: 0px;}

/*
*   Container that defines the Maximum width the content of the page can take
*/

.maxWidthPageContainer {
  max-width: var(--max-width-page-content);
  margin: auto;
  padding: 0px 3rem; /* in case the browser do not support min() */
  padding: 0px min(3rem, 4vw);
}

.fixedBG_Img{
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*
    Reverse an image
*/
.reverse {
  -webkit-transform: scaleX(-1); /* Safari & Chrome */
  -moz-transform: scaleX(-1);/* Firefox */
  -ms-transform: scaleX(-1); /* Internet Explorer */
  -o-transform: scaleX(-1); /* Opera */
  transform: scaleX(-1);
}


/*

  Remove some default styling of the button element

*/

button {
  background: unset;
  border: unset;
  cursor: pointer;
  padding: 0px;
}

.button {

  --buttonColor: var(--red);

  padding: 0.5rem 2rem;
  cursor: pointer;
  background-color: rgba(var(--buttonColor), 0.8);
  border-radius: 0.5rem;
  border-width: 3px; 
  border-color: rgb(var(--buttonColor));
  border-style: solid;
  color: white;

  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;


  &.button--reverse {
    border-width: 3px;
    background-color: rgba(var(--beige), 0);
    color: rgb(var(--buttonColor));

    &.button--white {
      color: rgb(var(--buttonColor));
    }

    &:hover {
      background-color: rgba(var(--buttonColor), 1);
      color: white;

      &.button--white {
        color: rgb(var(--blue))
      }
    }
  }

  &.button--disabled {
    background-color: grey;
    border-color: grey;
    cursor: not-allowed;
    opacity: 0.7;

    &:hover {
      background-color: grey;
      border-color: grey;
    }
  }

  &.button--pink{ --buttonColor: var(--pink);}
  &.button--blue{ --buttonColor: var(--blue);}
  &.button--green{ --buttonColor: var(--green);}
  &.button--yellow{ --buttonColor: var(--yellow);}
  &.button--grey{ --buttonColor: var(--grey);}
  &.button--white{ 
    --buttonColor: var(--beige);
    color: rgb(var(--red));
  }

  &:hover{
    background-color: rgba(var(--buttonColor), 1);
  }

  &:active{
    -webkit-transition: 0s;
    -moz-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
    border-color: rgba(var(--buttonColor), 0.4);
    background-color: rgba(var(--buttonColor), 0.4);
  }
}


/*
    Text highlight
*/

::-moz-selection { /* Code for Firefox */
  color: rgb(var(--beige));
  background: rgb(130, 130, 189);
}

::selection {
  color: rgb(var(--beige));
  background:rgb(130, 130, 189);
}


/*
 Override some default styling of the input element 
*/

input {

  /* variable to set equally the padding */
  --inputPadding: 0.5rem;

  background-color: unset;
  border: unset;
  color: unset;
  padding: var(--inputPadding);

  /* To modify
  **
  *
  *
  *
  */

  /* submit buttons */
  &[type='submit']{ 
    cursor: pointer; 
    padding: var(--inputPadding);
    margin: 0px 0.25em;
  }
}

/* text areas which are not submit buttons */
input:not([type='submit']), textarea{
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(255,255,255,0.5);
  transition: 0.2s;

  &:focus{
    border-bottom-color: rgba(255,255,255,1);
  }
}

input:focus, textarea:focus, select:focus{
  outline: none;  
}


/*
*      Asterix forrequired fields
*       Always positioned inside the label element
*/
.asterisk {
  color: rgb(167, 31, 31);
  float: left;
  font-size: 0.7rem;
}


/*
*
*
*     Custom scroll bar    
*
*
*/

::-webkit-scrollbar {
  width: 0.65rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #caccd4;
  /*margin-block: 0.5rem;
  border-radius: 0.5rem;*/
}

::-webkit-scrollbar-thumb {
  background: #555b78b4;
}

::-webkit-scrollbar-thumb:hover {
  background: #555B78;
}

@supports (scrollbar-color: #caccd4 #555B78) {
  * {
    scrollbar-color:#caccd4 #555B78;
    scrollbar-width: thin;
  }
}

.thinDarkScrollBar {

    ::-webkit-scrollbar {
      width: 0.45rem;
      height: 0.5rem;
    }

    ::-webkit-scrollbar-track {
      background: none;
      /*margin-block: 0.5rem;
      border-radius: 0.5rem;*/
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #373737b4;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #2e2e2ed5;
    }

    @supports (scrollbar-color: #caccd4 #373737b4) {
      * {
        scrollbar-color:#caccd4 #373737b4;
        scrollbar-width: thin;
      }
    }
}





/*
*
*
*     Media queries for responsive design
*
*
*
*/


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 700px) {

    html {
     /*  scroll-padding-top: 4.9rem;  */
    }
    html, body {
      font-size: 14px;
    }

    .scroll-padding-correction {
        padding-top: 4.9rem;
        margin-top: -4.9rem;
    }

}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
     
}

/* Extra large devices (large laptops and desktops, 1500px and up) */
@media only screen and (min-width: 1500px) {
     
}



